import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Typography, Box, Paper } from '@mui/material';
import { StandardTable } from "standard";
import API from "../utils/api";
import CopyableText from "./withdraw/CopyableText";

export function OpLogs({ pid, portionId }) {
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    if (pid) {
      API.logList(pid, portionId, v => setLogs(v));
    }
  }, [pid, portionId]);

  const translateOp = (op) => {
    switch (op) {
      case 'LOCK': return '锁定';
      case 'UNLOCK': return '解锁';
      case 'PORTION': return '分单';
      case 'PORTION_REMIT': return '交易成功';
      case 'PORTION_RECALL': return '交易撤单';
      case 'PORTION_FAIL': return '交易失败';
      case 'PORTION_PASS': return '交易通过';
      case 'REMIT': return '成功';
      default: return op;
    };
  }

  return (
    <Paper sx={{ minWidth: 750, padding: 1 }}>
      <Typography variant="h4" align="center">
        操作日志
      </Typography>
      <StandardTable
        columns={[
          {
            headerName: '操作', field: 'op', width: 100,
            renderCell: (params) => translateOp(params.row.op),
          },
          {
            headerName: '提款/存款订单号', field: 'platOrderId', width: 180,
            renderCell: (params) => (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // 使子元素在水平方向上居中
                '& > *:not(:last-child)': { mb: -0.5 },
              }}>
                <CopyableText label={params.row.platOrderId} />
                <CopyableText label={params.row.trxId} />
              </Box >)
          },
          { headerName: '备注', field: 'remark', width: 180, },
          { headerName: '创建人', field: 'creater' },
          { headerName: '创建时间', field: 'createTime', width: 180 },
        ]}
        getRowId={(row) => `${row.id}`}
        data={logs}
        query={() => { }}
        queryParams={null}
        version={0}
        rowHeight={40}
        notFoundSrc={`no_items_found.png`}
      />
    </Paper>
  )
}

OpLogs.propTypes = {
  pid: PropTypes.string,
  portionId: PropTypes.array,
};