import { Navigate } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import { Card } from '@mui/material';
import AppLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import LoginPage from './pages/login';
import Page404 from './pages/Page404';
import WithdrawPage from './pages/withdraw';
import PortionPage from './pages/portion';
import Workspace from './pages/workspace';

// 服务器api地址
export const serverURL = 'https://luluapi.doudoublog.com/lulu';

// 登录页面
export const loginPath = `/login`;

// 主页
export const mainPath = `/`;

export const isRelease = () => true;

// 路由组件
export const navConfig = [
  // {
  //   title: '提款',
  //   icon: <ReorderIcon />,
  //   path: `/withdraw`,
  // },
  // {
  //   title: '交易单',
  //   icon: <PaidIcon />,
  //   path: `/portion`,
  // },
  {
    title: '工作间',
    icon: <GroupIcon />,
    path: `/workspace`,
  },
];

function Element({ component }) {
  return (
    <Card sx={{ margin: 1 }}>
      {component}
    </Card>
  );
}

export const routesConfig = [
  {
    path: `/`,
    element: <AppLayout />,
    children: [
      { element: <Navigate to={`/workspace`} />, index: true },
      { path: 'withdraw', element: <Element component={<WithdrawPage />} /> },
      { path: 'portion', element: <Element component={<PortionPage />} /> },
      { path: 'workspace', element: <Element component={<Workspace />} /> },
    ],
  },
  {
    path: `/login`,
    element: <LoginPage />,
  },
  {
    path: `/`,
    element: <SimpleLayout />,
    children: [
      { element: <Navigate to={`/deposit`} />, index: true },
      { path: `/404`, element: <Page404 /> },
      { path: '*', element: <Navigate to={`/404`} /> },
    ],
  },
  {
    path: '*',
    element: <Navigate to={`/404`} replace />,
  },
];
