import { useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';

export default function InputSelect({ options, label, onChange, onInputChange }) {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => {
        setValue(newValue);
        if (onChange) {
          onChange(newValue && newValue.value);
        }
      }}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        if (onInputChange) {
          onInputChange(newInputValue);
        }
      }}
      options={options}
      getOptionLabel={(option) => {
        // 处理字符串输入和选项对象
        if (typeof option === 'string') {
          return option;
        }
        // 如果没有找到匹配的选项，返回输入的值
        if (option.inputValue) {
          return option.inputValue;
        }
        // 正常情况下返回选项的标签
        return option.label;
      }}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{
            overflow: 'auto',    // 隐藏溢出内容
            padding: '8px 16px',   // 保持一些内边距
          }}
        >
          {option.label}
        </li>
      )}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}

          label={label}
          placeholder={'请输入'}
          size='small'
        />
      )}
    />
  );
};
InputSelect.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
};
