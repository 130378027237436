import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Avatar, Button, Popover } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  useStore, useSnackbar
} from "standard";
import account from '../../../_mock/account';
import API from '../../../utils/api';
import { loginPath } from '../../../config';
import { signout } from '../../../utils/Auth';

export default function AccountPopover() {
  const { setStore } = useStore();
  const navigate = useNavigate();
  const { setSnackbar } = useSnackbar();
  const [open, setOpen] = useState(null);
  const [name, setName] = useState(null);

  useEffect(() => {
    API.init(message => setSnackbar(message, 'error'));
    API.me(result => {
      setStore(result);
      setName(result.name);
    });
  }, []);

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    signout();
    navigate(loginPath, { replace: true });
  };

  return (
    <>
      <Stack direction='row' spacing={2}>
        <Button
          style={{ textTransform: 'none' }}
          endIcon={<Avatar src={`${account.photoURL}`} alt="photoURL" />}
          label={name}
          onClick={(event) => {
            setOpen(event.currentTarget);
          }}
        >
          {name}
        </Button>
      </Stack>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 160,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }
        }}
      >
        <Stack alignContent='center' sx={{ p: 1 }}>
          <Button color='error' onClick={handleLogout} startIcon={<LogoutIcon />}>
            退出登录
          </Button>
        </Stack>
      </Popover>
    </>
  );
}
