import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// 计时组件
const TimeElapsed = ({ start, end, max = 'day' }) => {
  const [timeElapsed, setTimeElapsed] = useState('');
  const [color, setColor] = useState('green');

  useEffect(() => {
    const updateElapsed = () => {
      const now = end ? new Date(end) : new Date();
      const elapsed = Math.floor((now - new Date(start)) / 1000); // Difference in seconds
      const dayDuration = 3600 * 24;
      const days = Math.floor(elapsed / dayDuration); // Convert to days
      const hours = Math.floor((elapsed % dayDuration) / 3600); // Convert to hours
      const minutes = Math.floor(elapsed % dayDuration % 3600 / 60); // Remaining minutes
      const seconds = Math.floor(elapsed % 60); // Remaining seconds

      // Format the time as D HH:MM
      const dayF = days > 0 ? `${days}天` : '';
      if (max === 'day') {
        setTimeElapsed(
          `${dayF} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        );
      } else {
        setTimeElapsed(
          `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        );
      }

      if (hours > 0 || days > 0) {
        setColor('red');
      } else if (minutes >= 20) {
        setColor('chocolate')
      } else {
        setColor('green')
      }
    };

    updateElapsed(); // Initial update

    // Set an interval to update every second
    const intervalId = setInterval(updateElapsed, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, [start, end]);

  return <div style={{ color }}>{timeElapsed}</div>;
};
TimeElapsed.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  max: PropTypes.oneOf(["day", "hour"])
};

export default TimeElapsed;