import { Paper, Divider, Box, Typography, TableCell, TableContainer, Table, TableBody, TableRow } from "@mui/material";
import TimeElapsed from "./TimeElapsed";

export default function LockedCountdown({ rows, onClick }) {
  return (
    <Box sx={{
      position: 'absolute', // 或 'fixed' 根据需求
      top: '20%', // 调整为需要的位置
      left: '0%', // 调整为需要的位置
      zIndex: 3 // 确保这个值高于ComponentA的
    }}>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 550,
          border: '1px solid',
          borderColor: 'divider',
          width: 200
        }}
      >
        <Typography
          variant="h6"
          component="div"
          align="center"
          sx={{ padding: 0.5, backgroundColor: 'green', }}
        >
          已锁定
        </Typography>
        <Divider sx={{ width: '100%' }} />
        <Table
          stickyHeader
          size="small"
          sx={{
            backgroundColor: 'lightgreen',
          }}
        >
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  fontSize: 100,
                }}
                onClick={() => onClick(row)}
              >
                <TableCell
                  sx={{
                    fontSize: 25,
                    width: 100,
                    borderRight: '1px solid rgba(224, 224, 224, 1)',
                    color: 'red',
                    padding: 0,
                    fontFamily: 'Courier New',
                    fontWeight: 'bold',
                  }}
                  align="center"
                >{row.remainingAmount}
                </TableCell>
                <TableCell sx={{ fontSize: 15, width: 100, fontWeight: 'bold', fontFamily: 'Courier New' }} align="center">
                  <TimeElapsed start={row.auditTime} max="hour" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}