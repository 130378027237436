import PropTypes from 'prop-types';
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";

export default function MutiSelect({ options = [], label, values = [], onChange }) {
  const handleChange = (event) => {
    const { target: { value }, } = event;
    onChange(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const optionLabel = options.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});

  return (
    <FormControl fullWidth>
      <InputLabel
        size="small"
      >
        {label}
      </InputLabel>
      <Select
        size="small"
        autoWidth
        label={label}
        multiple
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        value={values}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: 0.2,
            overflow: 'hidden',
            overflowX: 'auto',
            '&::-webkit-scrollbar': { height: '8px' },
            '&::-webkit-scrollbar-thumb': { backgroundColor: '#888', borderRadius: '1px' },
          }}>
            {selected.map((value) => (
              <Chip size="small" key={value} label={optionLabel[value]} />
            ))}
          </Box>
        )}
      >
        {options.map(v => <MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>)}
      </Select>
    </FormControl>)
}

MutiSelect.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func
};
