import { useState } from "react";
import {
  Table, TableContainer, TableHead, TableBody, TableRow, TableCell,
  Paper, Divider, Typography, Stack, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio
} from "@mui/material";
import { useSnackbar } from "standard";
import { PortionStatus } from "../portion/OpMenu";

export function PortionLimit({ id, portions, countLimit, onSubmit }) {
  const count = portions.filter(v => v.status === 'WAITING').length
  const [limit, setLimit] = useState(countLimit)
  const { setSnackbar } = useSnackbar();
  const handleRadioChange = (event) => {
    if (count > event.target.value) {
      setSnackbar('拆单数量上限不能低于已有的拆单数量', 'error');
      setLimit(countLimit);
      return;
    }
    onSubmit(id, event.target.value)
    setLimit(event.target.value);
  };


  const limitSelection = () => {
    return (
      <FormControl>
        <FormLabel sx={{ display: 'block', textAlign: 'center' }} id="radio-buttons-group-label">拆单数量上限</FormLabel>
        <RadioGroup
          row
          aria-labelledby="radio-buttons-group-label"
          value={limit}
          name="radio-buttons-group"
          onChange={handleRadioChange}
        >
          {[1, 2, 3, 4, 5].map(v => <FormControlLabel value={v} control={<Radio />} label={v} />)}
        </RadioGroup>
      </FormControl>
    );
  }

  return (
    <Stack
      height={440}
      width={740}
      display="flex"
      alignItems="center"
      flexDirection="column"
      padding={2}
      spacing={1}
    >
      <Typography variant="h6" align="center">
        已开单({count})
      </Typography>

      <Divider sx={{ width: '100%' }} />


      <TableContainer
        component={Paper}
        sx={{ maxHeight: 250, border: '1px solid', borderColor: 'divider' }}
      >
        <Table stickyHeader size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">金额</TableCell>
              <TableCell align="center">存款订单号</TableCell>
              <TableCell align="center">状态</TableCell>
              <TableCell align="center">创建人</TableCell>
              <TableCell align="center">创建时间</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {portions.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center">{row.amount}</TableCell>
                <TableCell align="center">{row.trxId}</TableCell>
                <TableCell align="center"><PortionStatus status={row.status} /></TableCell>
                <TableCell align="center">{row.creater}</TableCell>
                <TableCell align="center">{row.createTime}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ width: '100%' }} />
      {limitSelection()}
    </Stack>
  )
}