import { useState } from 'react';
import {
  Button, Divider, Stack, TextField, Typography
} from '@mui/material';
import { useSnackbar } from 'standard';

export default function TrxId({ trxId, onSubmit }) {
  const { setSnackbar } = useSnackbar();
  const [value, setValue] = useState(null);
  const handleSubmit = () => {
    if (!value) {
      setSnackbar('请输入新的存款订单号', 'error')
      return;
    }
    onSubmit(value);
  }
  return (
    <Stack
      padding={1}
      spacing={1}
      sx={{
        width: 320,
        height: 220,
      }}
    >
      <Typography variant="h6" align="center">
        修改存款订单号
      </Typography>
      <Divider sx={{ width: '100%' }} />
      <TextField
        label='旧存款订单号'
        disabled
        fullWidth
        variant="outlined"
        size='small'
        value={trxId}
        aria-readonly
      />
      <TextField
        label='新存款订单号'
        size='small'
        fullWidth
        variant="outlined"
        multiline
        required
        value={value}
        onChange={(event) => setValue(event.target.value)}
        sx={{
          '& .MuiOutlinedInput-input': {
            padding: '1px', // 减少内边距以使文本更靠近左上角
          },
          '& .MuiOutlinedInput-root': {
            alignItems: 'flex-start' // 确保输入框的内容从顶部开始
          },
        }}
      />
      <Button
        variant='contained'
        onClick={handleSubmit}
      >
        确认
      </Button>
    </Stack>
  );
}
