import {
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { LockOpen, Lock, LockPerson, HealthAndSafety } from '@mui/icons-material';

export default function LockGroup({ id, me, auditor, isUsable, onLock, onUnlock, onOpenPortions, onUsable }) {
  let buttons = []
  const unlock = "直接解锁";
  const open = "进入分单页面";
  const lock = "直接锁定";
  const portions = "锁定并进入分单页面";
  if (auditor !== null) {
    if (auditor === me) {
      buttons = [
        <Tooltip key={unlock} title={unlock}>
          <IconButton disabled={!isUsable} size="small" color="success" onClick={() => onUnlock(id)}><LockPerson /></IconButton>
        </Tooltip>,
        <Tooltip key={open} title={open}>
          <Button disabled={!isUsable} onClick={() => onOpenPortions(id)} size="small" variant="outlined" color="success">查看</Button>
        </Tooltip>
      ]
    } else {
      buttons = [
        <IconButton key={'lockIamge'} disabled size="small" ><Lock /></IconButton>,
        <Button key={'lockIamgeLabel'} disabled size="small" variant="outlined" color="inherit">已锁定</Button>
      ]
    }
  } else {
    buttons = [
      <Tooltip key={lock} title={lock}>
        <IconButton disabled={!isUsable} onClick={() => onLock(id)} size="small" ><LockOpen /></IconButton>
      </Tooltip>,
      <Tooltip key={portions} title={portions}>
        <Button disabled={!isUsable} onClick={() => onOpenPortions(id)} size="small" variant="contained" color="success">锁定</Button>
      </Tooltip>
    ]
  }

  buttons.push(
    <Tooltip key={'usable'} title={isUsable ? '关闭' : '开启'}>
      <IconButton size="small" onClick={() => onUsable(id, !isUsable)}>
        <HealthAndSafety color={isUsable ? 'success' : ''} />
      </IconButton>
    </Tooltip>
  )
  return (
    <>
      {buttons}
    </>
  )
}