import { useState } from 'react';
import { Button, Menu, MenuItem, Popover, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import {
  useSnackbar,
} from "standard";
import API from "../../utils/api";
import { Status } from '../Status';
import TrxId from './TrxId';
import SingleInputDialog from '../SingleInputDialog';
import { OpLogs } from '../OpLogs';

export default function OpMenu({ id, pid, status, trxId, afterOp, alarm }) {
  const { setSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTrxId, setOpenTrxId] = useState(false);
  const open = Boolean(anchorEl);
  const [remit, setRemit] = useState(false);
  const [passRemark, setPassRemrk] = useState(false);
  const [failRemark, setFailRemrk] = useState(false);
  const [recallRemark, setRecallRemrk] = useState(false);
  const [amountEnable, setAmountEnable] = useState(false);
  const [opLogsEnable, setOpLogsEnable] = useState(false);

  const handleRemit = () => {
    async function func() {
      const result = await API.spPortionRemit(id);
      showResult(result, '已【成功】');
      afterOp();
    }
    func();
    setRemit(false);
  };

  const handleRecall = (remark) => {
    async function func() {
      const result = await API.spPortionRecall(id, remark);
      showResult(result, '已【撤单】');
      afterOp();
    }
    func();
    setRecallRemrk(false);
  };

  const handlePass = (remark) => {
    async function func() {
      const result = await API.spPortionPass(id, remark);
      showResult(result, '已【通过】');
      afterOp();
    }
    func();
    setPassRemrk(false);
  };

  const handleFail = (remark) => {
    async function func() {
      const result = await API.spPortionFail(id, remark);
      showResult(result, '已【失败】');
      afterOp();
    }
    func();
    setFailRemrk(false);
  };

  const handleTrxIdSubmit = (trxId) => {
    async function func() {
      const result = await API.spPortionTrxId(id, trxId);
      showResult(result, '修改存款订单号成功')
      afterOp()
    }
    func();
    setOpenTrxId(false)
  };

  const handleAlarmSubmit = () => {
    async function off() {
      const result = await API.spPortionAlarmOff(id);
      showResult(result, '已关闭警报')
      afterOp()
    }
    async function on() {
      const result = await API.spPortionAlarmOn(id);
      showResult(result, '已开启警报')
      afterOp()
    }
    if (alarm) {
      on();
      return
    }
    off();
  };

  const handleChangeAmount = (amount) => {
    async function func() {
      const result = await API.spPortionAmount(id, amount);
      showResult(result, '修改交易单金额成功')
      afterOp()
    }
    func();
    setAmountEnable(false)
  };


  const showResult = (result, successText) => {
    if (result && result.success) {
      setSnackbar(successText, 'success');
    } else {
      setSnackbar(result.message, 'error');
    }
  }

  const remitDialog = () => {
    return (
      <Dialog
        open={Boolean(remit)}
        onClose={() => {
          setAnchorEl(null);
          setRemit(null);
        }}
      >
        <DialogTitle>{"再次确认"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`您确定要执行成功吗？`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemit} color="primary" autoFocus>
            确定
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const item = (text, color, onClick) => {
    return (
      <MenuItem key={text} sx={{ justifyContent: 'center', textAlign: 'center' }}>
        <Button
          variant="text"
          color={color}
          onClick={() => {
            setAnchorEl(null);
            onClick();
          }}
          size='small'
        >
          {text}
        </Button>
      </MenuItem>
    );
  }

  const itemSuccess = () => {
    return item('成功', 'success', () => setRemit(true));
  }

  const itemPass = () => {
    return item('通过', 'warning', () => setPassRemrk(true));
  }

  const itemFail = () => {
    return item('失败', 'error', () => setFailRemrk(true));
  }

  const itemRecall = () => {
    return item('撤单', 'error', () => setRecallRemrk(true));
  }

  const itemChangeAmount = () => {
    return item('金额', 'secondary', () => setAmountEnable(true));
  }

  const itemLogs = () => {
    return item('日志', 'secondary', () => setOpLogsEnable(true));
  }

  const itemTrxId = () => {
    return item('存款订单号', 'secondary', () => {
      setOpenTrxId(true);
      setAnchorEl(null);
    })
  }

  const items = () => {
    const alarmButton = alarm
      ? item('开启警报', 'error', handleAlarmSubmit)
      : item('关闭警报', 'error', handleAlarmSubmit)
    const opLogs = itemLogs();
    switch (status) {
      case 'WAITING': return [opLogs, itemSuccess(), itemPass(), itemFail(), itemTrxId(), alarmButton, itemChangeAmount()];
      case 'SUCCESS': return [opLogs, itemRecall()];
      case 'PASS': return [opLogs, itemSuccess(), itemFail(), alarmButton];
      default: return [opLogs];
    };
  }

  const button = () => {
    if (status === 'WAITING') {
      return (
        <Button
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          操作
        </Button>
      )
    }
    return (
      <PortionStatus
        status={status}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />)
  }

  const anchorPosition = { top: 60, left: window.innerWidth / 2 };
  return (
    <div>
      <Popover
        open={Boolean(openTrxId)}
        onClose={() => setOpenTrxId(null)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <TrxId trxId={trxId} onSubmit={handleTrxIdSubmit} />
      </Popover>
      <Popover
        open={Boolean(opLogsEnable)}
        onClose={() => setOpLogsEnable(false)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <OpLogs pid={pid} portionId={id} />
      </Popover>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items()}
      </Menu>
      <SingleInputDialog
        rows={4}
        open={passRemark}
        title='通过'
        onSubmit={(remark) => { handlePass(remark) }}
        onClose={() => setPassRemrk(false)}
      />
      <SingleInputDialog
        rows={4}
        open={recallRemark}
        title='撤单'
        onSubmit={(remark) => { handleRecall(remark) }}
        onClose={() => setRecallRemrk(false)}
      />
      <SingleInputDialog
        rows={4}
        open={failRemark}
        title='失败'
        onSubmit={(remark) => { handleFail(remark) }}
        onClose={() => setFailRemrk(false)}
      />
      <SingleInputDialog
        open={Boolean(amountEnable)}
        title='修改金额'
        onSubmit={handleChangeAmount}
        onClose={() => {
          setAmountEnable(false);
        }}
      />
      {remitDialog()}
      {button()}
    </div>
  );
}

export function PortionStatus({ status, onClick }) {
  switch (status) {
    case 'SUCCESS': return <Status label='成功' color='success' onClick={onClick} />
    case 'FAILED': return <Status label='失败' color='error' onClick={onClick} />
    case 'RECALL': return <Status label='撤单' color='error' onClick={onClick} />
    case 'PASS': return <Status label='通过' color='warning' onClick={onClick} />
    default: return <Status label='等待' color='info' onClick={onClick} />
  }
}