import { useState, useEffect } from "react";
import { Button, Box, Typography, Chip, Divider, IconButton, Menu, } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StandardSearchGroup } from "standard";
import API from "../../utils/api";
import InputSelect from "../InputSelect";


export default function Workspace() {
  const [queryParams, setQueryParams] = useState({
    attendantId: null,
  });
  const [rows, setRows] = useState([]);
  const [inputName, setInputName] = useState();
  const [attendants, setAttendants] = useState([]);
  const [summary, setSummary] = useState({
    total: 0,
    open: 0
  });

  useEffect(() => {
    // 初始化所有数据
    query();
    async function func() {
      const info = await API.wsAllAttendants(queryParams);
      info.result.sort((a, b) => b.name.localeCompare(a.name));
      setAttendants(info.result);
    }
    func();
  }, []);

  useEffect(() => {
    // 设置定时查询
    const intervalId = setInterval(query, 5000);
    return () => clearInterval(intervalId);
  }, [queryParams]);

  const query = async () => {
    const info = await API.wsAttendants(queryParams);

    // info.result = Array(3).fill().map((_, index) => ({
    //   id: index + 1,
    //   name: `Item ${index + 1}`,
    //   teamName: 'teanName', online: false, open: false, relax: false, zoom: false
    // }));

    info.result = sortRows(info.result);
    setRows(info.result);
    setSummary({
      total: info.result.length,
      open: info.result.filter(v => v.open).length
    })
  }

  const handleAttendantOpen = (id, active) => {
    async function func() {
      const info = await API.wsAttendantOpen(id, active);
      replaceRow(info.result)
    }
    func();
  }

  const replaceRow = (row, resort = false) => {
    const index = rows.findIndex(v => v.id === row.id);
    if (index >= 0) {
      let newRows = [...rows]
      newRows[index] = row;
      if (resort) {
        newRows = sortRows(newRows);
      }
      setRows(newRows);
    }
  }

  const sortRows = (rows) => {
    rows.sort((a, b) => {
      // online 为 true 的排在前面
      if (a.open !== b.open) {
        return b.open ? 1 : -1;
      }

      // relax 为 true 的排在前面
      if (a.relax !== b.relax) {
        return b.relax ? 1 : -1;
      }

      return b.openUpdateTime.localeCompare(a.openUpdateTime);
    })
    return rows;
  }

  return (
    <Box
      sx={{
        height: 'calc(100vh - 70px)',  // 填满整个视口高度
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',  // 防止外层容器出现滚动条
        gap: 1,
      }}>

      <StandardSearchGroup
        options={[
          {
            type: 'component', width: 150, render: () => {
              return (
                <InputSelect
                  options={attendants.map(v => { return { label: v.name, value: v.id } })}
                  label="员工"
                  onChange={attendantId => {
                    setQueryParams({ ...queryParams, attendantId });
                    if (!attendantId) {
                      setInputName(null);
                    }
                  }}
                  onInputChange={setInputName}

                />
              )
            }
          },
        ]}
        setVersion={() => { query() }}
        setQueryParams={(v) => {
          setQueryParams({ ...queryParams, ...v })
        }}
      />

      <Divider />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 1,
          padding: 1,
          overflowY: 'auto',  // 允许垂直滚动

          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#555',
            },
          },
        }}
      >
        {rows
          .filter(v => !inputName || v.name.toLowerCase().includes(inputName.toLowerCase()))
          .map(v =>
            <AttendantCard
              key={v.id}
              id={v.id}
              name={v.name}
              teamName={v.teamName}
              online={v.online}
              open={v.open}
              relax={v.relax}
              zoom={v.zoom}
              onOpenClick={() => handleAttendantOpen(v.id, !v.open)}
            />)}
      </Box>
      <Box sx={{
        borderTop: `1px solid rgba(128, 128, 128, 0.1)`,
        width: '100%',
        padding: 0.5,
        display: "flex",
        direction: 'row',
        justifyContent: "space-around",
      }}>
        <Typography color={'error'}>总数: {summary.total}</Typography>
        <Typography color={'error'}>营业: {summary.open}</Typography>
      </Box>
    </Box>
  )
}

const AttendantCard = ({ name, teamName, online, open, relax, zoom, onOpenClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const chip = (key, label, color, filled = true) => {

    return (
      <Chip
        key={key}
        sx={{
          width: 60
        }}
        label={label}
        size='small'
        color={color}
        variant={filled ? 'filled' : 'outlined'}
      />)
  }

  const working = open && !relax;
  const offlineAlarm = working && !online;

  const states = () => {
    const onColor = 'success';
    const offColor = 'default'
    const alarmColor = 'error'
    let onlineColor = online ? onColor : offColor;
    if (offlineAlarm) {
      onlineColor = alarmColor;
    }

    let workingState = chip(1, working ? '工作中' : '休息中', working ? onColor : offColor);
    if (open && relax) {
      workingState = chip(1, '休息中', alarmColor, false);
    }

    return [
      workingState,
      chip(2, online ? '在线' : '离线', onlineColor),
      chip(3, 'Zoom', zoom ? onColor : offColor),
    ];

  }

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Button
          color={open ? "error" : "success"}
          size="small"
          disabled={!online && !open}
          onClick={() => {
            setAnchorEl(null)
            onOpenClick()
          }}
        >
          {open ? "关闭" : "打开"}
        </Button>

      </Menu>
      <Box
        sx={{
          flexGrow: 1,    // 允许增长
          flexBasis: 0,   // 基准尺寸为0，允许完全根据flexGrow来分配空间
          minWidth: 270,
          maxWidth: 330,
          height: 100,
          border: open ? '1px solid #91ff4d' : '1px solid #cc',
          borderRadius: 3,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          boxShadow: 2,
          cursor: 'pointer', // 添加指针样式以指示可点击
          transition: 'background-color 0.3s ease', // 添加过渡效果
          '&:hover': {
            backgroundColor: 'lightskyblue', // 悬停时的背景色
          },
          backgroundColor: offlineAlarm ? 'pink' : 'whitesmoke'
        }}
      // onClick={onClick}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography fontWeight="bold" >{name}</Typography>
          <Typography >{teamName}</Typography>
          <IconButton
            size="small"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>

        <Divider />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          {states()}
        </Box>
      </Box >
    </>
  );
};