import { Tooltip, Typography } from "@mui/material";

export default function CopyableText({ label, copiedText = label, onClick, color = 'blue', style }) {
  const copied = () => {
    if (onClick) {
      onClick()
    }
    navigator.clipboard.writeText(copiedText);
  }
  return (
    <Tooltip title="复制">
      <Typography
        sx={{
          cursor: 'pointer', // 改变鼠标指针为手形，增强交互体验
          transition: 'color 0.3s', // 添加颜色变化的过渡效果
          color, // 初始颜色
          '&:hover': {
            color: 'Navy', // 鼠标悬停时的颜色
          },
        }}
        style={{ fontSize: 13, ...style }}
        onClick={copied}>
        {label}
      </Typography>
    </Tooltip>
  )
}