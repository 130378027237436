import { BrowserRouter, useRoutes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GlobalProvider, Heartbeat } from 'standard';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { routesConfig } from './config';
import API from './utils/api';

function Router() {
  const routes = useRoutes(routesConfig);
  return routes;
}

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Heartbeat callback={API.heartbeat} interval={60000} />
          <GlobalProvider>
            <Router />
          </GlobalProvider>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
