import { useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, Button, Stack, Grid, Divider, IconButton, Input } from '@mui/material';
import { Inspector, useSnackbar } from 'standard';
import API from "../../utils/api";
import { PortionStatus } from '../portion/OpMenu';

function Row({ values }) {
  const widths = [3, 4, 3, 2]
  return (
    <Grid
      container
      height={40}
      backgroundColor='#e6ede8'
      justifyContent="center"
      alignItems='center'
    >
      {values.map((v, index) => (
        <Grid
          key={index}
          container
          item
          xs={widths[index]}
          justifyContent="center"
        >{v}
        </Grid>))}
    </Grid>
  );
}

export function Potions({ details, portions = [], onSubmit, onClose }) {
  const [order, setOrder] = useState(null);
  const [add, setAdd] = useState(false);
  const { setSnackbar } = useSnackbar();
  const opButton = () => {
    if (add) {
      return (
        <IconButton
          color='error'
          onClick={() => {
            setAdd(false);
            setOrder(null);
          }}>
          <RemoveIcon />
        </IconButton>
      );
    }
    return (
      <IconButton color='success' onClick={() => setAdd(true)}>
        <AddIcon />
      </IconButton>
    );
  }

  const handleSubmit = () => {
    if (!order || !order.trxId) {
      setSnackbar('请输入存款订单号', 'error');
      return;
    }
    setOrder(null)
    onSubmit(details.id, order.trxId)
    setAdd(false);
  }

  const handleOrderInput = async (id) => {
    const info = await API.findDepositOrder(id);
    if (!info.success) {
      setSnackbar('查询订单号错误', 'error');
      return;
    }
    setOrder({ trxId: id, amount: info.result.amount });
  }

  const addItem = () => {
    return (
      <Row key='add' values={[
        <Input
          inputProps={{ min: 0, style: { textAlign: 'center' } }} // the change is here
          placeholder="金额"
          type='number'
          value={order != null ? order.amount : ''}
          readOnly
        />,
        <Input
          inputProps={{ min: 0, style: { textAlign: 'center' } }} // the change is here
          placeholder="请输入存款订单号"
          onChange={(event) => {
            handleOrderInput(event.target.value);
          }}
        />,
        '',
        (<Button
          size='small'
          color='success'
          variant='contained'
          onClick={handleSubmit}
          startIcon={<AddCircleOutlineIcon />}
        >
          添加
        </Button>)
      ]} />);
  }
  return (
    <Box>
      <Inspector title={'交易面板'} infos={[
        { label: '商户', value: details.mchName },
        { label: '商户订单号', value: details.orderId },
        { label: '账户名', value: details.accountName },
        { label: '平台订单号', value: details.platOrderId },
        { label: '卡号', value: details.cardId },
        { label: '金额', value: details.amount },
        { label: '银行名称', value: details.bankName },
        { label: '剩余金额', value: details.remainingAmount },
      ]} />
      <Box sx={{ margin: 3, overflow: 'auto', height: 200 }}>
        <Row values={['金额', '存款订单号', '状态', opButton()]} />
        {add && addItem()}
        {portions.map(v => (<Row key={v.id} values={[v.amount, v.trxId, (<PortionStatus status={v.status} />), '']} />))}
      </Box>
      <Divider />
      <Stack
        direction='row'
        spacing={10}
        alignContent='center'
        justifyContent='center'
        sx={{ p: 3 }}>
        <Button variant='outlined' startIcon={<CloseIcon />} onClick={() => onClose(details.id, false)}>
          关闭
        </Button>
      </Stack>
    </Box>);
}

Potions.propTypes = {
  id: PropTypes.string,
  infos: PropTypes.string
}; 