import { get, deleteRequest, post, put } from "./request";

// 请求错误回调
let errorCallback = null;
const fetchThen = (v, onSuccess) => {
  if (v.success) {
    if (onSuccess) {
      onSuccess(v.result);
    }
    return v.result;
  }
  return errorCallback(v.message);
}
const API = {
  init(onError) {
    errorCallback = onError;
  },

  // workspace attendants
  async wsAllAttendants() {
    return get(`/workspace/attendants/all`);
  },

  async wsCheckAttendant(id) {
    return get(`/workspace/attendants/${id}`);
  },

  async wsAttendants(data = {}) {
    return get(`/workspace/attendants`, data);
  },

  async wsAttendantOpen(id, active) {
    return put(`/workspace/attendants/${id}/open`, { active });
  },


  // deposit order
  async findDepositOrder(id) {
    return get(`/deposit/order/${id}`);
  },

  async logList(id, portionId, onSuccess) {
    return get(`/sp/${id}/logs`, { portionId })
      .then(v => fetchThen(v, onSuccess));
  },

  // sp
  async spTasks(data = {}) {
    return get('/sp/tasks', data);
  },

  async spLock(id) {
    return post(`/sp/tasks/${id}/auditor`);
  },

  async spUnlock(id) {
    return deleteRequest(`/sp/tasks/${id}/auditor`);
  },

  async spBankName(id, bankName) {
    return put(`/sp/tasks/${id}/bankName`, { bankName });
  },

  async spPortionCountLimit(id, portionCountLimit) {
    return put(`/sp/tasks/${id}/portionCountLimit`, { portionCountLimit });
  },

  async spPriority(id, priority) {
    return put(`/sp/tasks/${id}/priority`, { priority });
  },

  async spIsUsable(id, isUsable) {
    return put(`/sp/tasks/${id}/isUsable`, { isUsable });
  },

  async spRemark(id, remark) {
    return put(`/sp/tasks/${id}/remark`, { remark });
  },

  async spInfo(id) {
    return get(`/sp/${id}`);
  },

  async spPortion(id, trxId) {
    return post(`/sp/tasks/${id}/portions/${trxId}`);
  },

  async spPortionRemit(id) {
    return put(`/sp/portions/${id}/status/remit`);
  },

  async spPortionRecall(id, remark) {
    return put(`/sp/portions/${id}/status/recall`, { remark });
  },

  async spPortionPass(id, remark) {
    return put(`/sp/portions/${id}/status/pass`, { remark });
  },

  async spPortionFail(id, remark) {
    return put(`/sp/portions/${id}/status/fail`, { remark });
  },

  async spPortions(id) {
    return get(`/sp/${id}/portions`)
      .then(v => {
        if (v.success === false && this.errorCallback) {
          this.errorCallback(v.message);
        }
        return v;
      });
  },

  async spPortionInfo(id) {
    return get(`/sp/portions/${id}`);
  },

  async spPortionTrxId(id, trxId) {
    return put(`/sp/portions/${id}/trxId`, { trxId });
  },

  async spPortionsPage(data) {
    return get(`/sp/portions`, data);
  },

  async spPortionAlarmOn(id) {
    return put(`/sp/portions/${id}/alarmTime`);
  },

  async spPortionAlarmOff(id) {
    return deleteRequest(`/sp/portions/${id}/alarmTime`);
  },

  async spPortionAlarms(onSuccess) {
    return get(`/sp/portions/alarms`)
      .then(v => fetchThen(v, onSuccess));
  },

  async spPortionAmount(id, amount) {
    return put(`/sp/portions/${id}/amount`, { amount });
  },

  async spSummary() {
    return get('/sp/summary');
  },
  // secure
  async gauthVerify(code) {
    return get(`/secure/gauth/${code}`);
  },

  async gauthConfirm(code) {
    return post('/secure/gauth', { code });
  },

  async gauthReset(code) {
    return deleteRequest('/secure/gauth', { code });
  },

  async resetPassword(oldPassword, newPassword) {
    return put('/secure/password', { oldPassword, newPassword });
  },

  async secret(gAuthCode) {
    return get('/secret', { gAuthCode });
  },

  async heartbeat() {
    return get('/profile/heartbeat');
  },

  async me(onSuccess) {
    get('/profile')
      .then(v => fetchThen(v, onSuccess));
  },
}

export default API;