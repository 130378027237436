import { useState } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default function SingleInputDialog({ open, onSubmit, onClose, title, description, rows = 1 }) {
  const [value, setValue] = useState();
  return (
    <Dialog
      open={Boolean(open)}
    >
      <DialogTitle align='center'>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
        <TextField
          size="small"
          variant="outlined"
          multiline
          rows={rows}
          autoFocus
          margin="dense"
          id="name"
          fullWidth
          onChange={(event) => setValue(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={() => onSubmit(value)}>确认</Button>
      </DialogActions>
    </Dialog>
  );
}  