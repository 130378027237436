import PropTypes from 'prop-types';
import {
  Button
} from "@mui/material";
import { Done, AccessTime, Loop, Clear } from '@mui/icons-material';

function RoundedButton({ icon, label, color, onClick }) {
  const sx = {
    borderRadius: '10px', // 圆角半径
    padding: '10px 10px', // 按钮内边距
    display: 'flex',
    alignItems: 'center', // 垂直居中
    height: '20px',
  };
  return (
    <Button
      sx={sx}
      startIcon={icon}
      variant='outlined'
      color={color}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

export function Status({ label, color, icon, onClick }) {
  switch (color) {
    case 'success':
      return <RoundedButton icon={icon ?? <Done />} color='success' label={label} onClick={onClick} />
    case 'error':
      return <RoundedButton icon={icon ?? <Clear />} color='error' label={label} onClick={onClick} />
    case 'warning':
      return <RoundedButton icon={icon ?? <Loop />} color='warning' label={label} onClick={onClick} />
    default:
      return <RoundedButton icon={icon ?? <AccessTime />} color='info' label={label} onClick={onClick} />
  }
}
Status.propTypes = {
  label: PropTypes.string,
  color: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
  icon: PropTypes.node,
};