import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Nav } from 'standard';
import Header from './header';
import { navConfig } from '../../config';

const APP_BAR_MOBILE = 50;
const APP_BAR_DESKTOP = 50;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP,
  },
}));

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const header = (
    <StyledAccount>
      <Box component='img' src={`logo.svg`} height={40} />
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2" fontSize={25} sx={{ color: 'white' }}>
          LuLu后台
        </Typography>
      </Box>
    </StyledAccount>
  );
  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav
        data={navConfig}
        openNav={open}
        onCloseNav={() => setOpen(false)}
        header={header}
      />
      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
