import { useState } from "react";
import {
  Stack, Typography, Divider, Button, TextField
} from "@mui/material";

export default function Remark({ value, onSubmit }) {
  const [text, setText] = useState(value);
  const handleSubmit = () => {
    onSubmit(text);
  }
  return (
    <Stack
      height={240}
      width={440}
      display="flex"
      alignItems="center"
      flexDirection="column"
      padding={2}
      spacing={1}
    >
      <Typography variant="h6" align="center">
        修改备注
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        multiline
        rows={4}
        value={text}
        onChange={(event) => setText(event.target.value)}
        sx={{
          '& .MuiOutlinedInput-input': {
            padding: '1px', // 减少内边距以使文本更靠近左上角
          },
          '& .MuiOutlinedInput-root': {
            alignItems: 'flex-start' // 确保输入框的内容从顶部开始
          },
        }}
        defaultValue={text}
      />
      <Button
        onClick={handleSubmit}
        variant="contained"
      >确认
      </Button>
    </Stack>
  );
}