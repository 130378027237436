import { useState } from 'react';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';

export default function Priority({ amount, value, onSubmit }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const options = [
    { value: 'LOW', label: '低', color: 'green' },
    { value: 'MEDIUM', label: '中', color: 'red' },
    { value: 'HIGH', label: '高', color: 'black' }
  ]

  const handleSubmit = (value) => {
    onSubmit(value);
    setAnchorEl(null);
  }
  let color;
  switch (value) {
    case 'LOW': color = 'green'; break;
    case 'MEDIUM': color = 'red'; break;
    case 'HIGH': color = 'black'; break;
    default: color = 'green'; break;
  }
  return (
    <div>
      <Tooltip title="设置订单优先级">
        <Button
          size='small'
          id="basic-button"
          aria-controls={Boolean(anchorEl) ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          sx={{
            color
          }}
        >
          {amount}
        </Button>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options
          .filter(v => v.value !== value)
          .map(v =>
            <MenuItem key={v.label} onClick={() => handleSubmit(v.value)}>
              <div style={{ color: v.color }}>{v.label}</div>
            </MenuItem>)}

      </Menu>
    </div>
  )

}