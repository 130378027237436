import { serverURL } from '../config';
import { getToken, onAuthFailed } from './Auth';

export async function post(path, data = {}) {
  return request(`${serverURL}${path}`, 'post', toForm(data));
}

export async function put(path, data = {}) {
  return request(`${serverURL}${path}`, 'put', toForm(data));
}

export async function deleteRequest(path, data = {}) {
  return request(`${serverURL}${path}`, 'delete', toForm(data));
}

export async function get(path, data = {}) {
  const queryString = toForm(data);
  return request(`${serverURL}${path}?${new URLSearchParams(queryString).toString()}`, 'get');
}

export function toForm(data) {
  const body = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value != null) {
      body.append(key, value)
    }
  })
  return body;
}

export async function request(url, method, body, retry = false) {
  const token = getToken();
  const headers = {}
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const response = await fetch(url, {
    method,
    body,
    headers,
  }).catch((err) => {
    return {
      success: false,
      message: err.message
    }
  });

  if (response.ok) {
    // 图片
    if (response.headers.get('Content-Type').indexOf('image') !== -1) {
      return response;
    }
    return response.json();
  }

  // 刷新token
  if (response.status === 401 && !retry) {
    return onAuthFailed(url, method, body)
  }

  if (response.status === 403) {
    return {
      success: false,
      message: "没有权限"
    }
  }

  return response;
}

export const modeName = (value) => {
  switch (value) {
    case 'BANK_CARD': return '银行卡转账';
    case 'R_DPAY_U': return '发R存U';
    case 'ETHEREUM': return '以太坊';
    default:
      return value;
  }
}
