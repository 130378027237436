import { useState, useEffect } from "react";
import {
  Box, Button, Popover, Stack, Tooltip
} from "@mui/material";
import {
  useGridApiRef,
  gridVisibleColumnDefinitionsSelector,
  gridExpandedSortedRowIdsSelector,
} from '@mui/x-data-grid';
import dayjs from "dayjs";
import {
  StandardSearchGroup, StandardTable, useStore, useSnackbar
} from "standard";
import './styles.css';
import API from "../../utils/api";
import TimeElapsed from "./TimeElapsed";
import { Potions } from "./Portions";
import { PortionLimit } from "./PortionLimit";
import Priority from "./Priority";
import LockGroup from "./LockGroup";
import Remark from "./Remark";
import LockedCountdown from "./LockedCountdown";
import CopyableText from "./CopyableText";
import SingleInputDialog from "../SingleInputDialog";
import { OpLogs } from "../OpLogs";


export default function WithdrawPage() {
  const rowHeight = 40
  const [queryParams, setQueryParams] = useState({
    timeStart: null,
    timeEnd: null,
    orderId: '',
  });
  const [version, setVersion] = useState(0);
  const [summary, setSummary] = useState({});
  const { store } = useStore();
  const { setSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const apiRef = useGridApiRef();
  const [coordinates, setCoordinates] = useState({
    rowIndex: 0,
    colIndex: 5,
  });
  const [highlightRow, setHighlightRow] = useState(null);

  const [portions, setPortions] = useState(null);
  const [portionLimit, setPortionLimit] = useState(null);
  const [remark, setRemark] = useState(null);
  const [spIdForBankName, setSpIdForBankName] = useState(null);
  const [opLogsId, setOpLogsId] = useState(null);

  // 跳转选定行
  useEffect(() => {
    const { rowIndex, colIndex } = coordinates;
    apiRef.current.scrollToIndexes(coordinates);
    const top = (rowIndex - 8) * 40;
    if (top > 0) {
      apiRef.current.scroll({ top: (rowIndex - 8) * rowHeight });
    }
    const id = gridExpandedSortedRowIdsSelector(apiRef)[rowIndex];
    const column = gridVisibleColumnDefinitionsSelector(apiRef)[colIndex];
    apiRef.current.setCellFocus(id, column.field);
  }, [apiRef, coordinates]);

  // 定时器查询
  useEffect(() => {
    const timerId = setInterval(() => {
      querySummary()
    }, 7000);
    // 组件卸载时清理定时器
    return () => clearInterval(timerId);
  }, []);

  // store 加载成功后刷新
  useEffect(() => {
    query(queryParams);
  }, [store.name]);

  const query = async (params) => {
    querySummary(params);
    const info = await API.spTasks(params);
    // const info = {
    //   result: [],
    // }
    // for (let i = 100; i < 1500; i += 1) {
    //   info.result.push({
    //     id: i,
    //     remainingAmount: i,
    //   })
    // }
    info.result = sortRows(info.result);
    setRows(info.result);
    return {
      list: info.result,
      pagination: {
        total: info.result.length
      }
    };
  }

  const sortRows = (rows) => {
    rows.sort((a, b) => b.remainingAmount - a.remainingAmount)
    const myLocked = rows.filter(v => v.auditor === store.name);
    if (myLocked.length !== 0) {
      const notMyLocked = rows.filter(v => v.auditor !== store.name);
      rows = myLocked.concat(notMyLocked);
    }
    return rows;
  }

  const querySummary = async () => {

    const info = await API.spSummary();
    if (info && info.success) {
      setSummary({
        total: info.result.total,
        remaining: info.result.remaining,
        count: info.result.count,
      })
    }
  }

  const replaceRow = (row, resort = false) => {
    const index = rows.findIndex(v => v.id === row.id);
    if (index >= 0) {
      let newRows = [...rows]
      newRows[index] = row;
      if (resort) {
        newRows = sortRows(newRows);
      }
      setRows(newRows);
    }
  }

  const requestAndReplace = async (func, message, resort) => {
    const info = await func();
    if (info && info.success) {
      setSnackbar(message, 'success');
      replaceRow(info.result, resort)
    } else {
      setSnackbar(info.message, 'error');
    }
    return info;
  }

  const handleLock = (id) => {
    requestAndReplace(() => API.spLock(id), '锁定成功', true);
  }
  const handleUnlock = (id) => {
    requestAndReplace(() => API.spUnlock(id), '解锁成功', true);
  }

  const handlePortionCountLimit = (id, limit) => {
    requestAndReplace(() => API.spPortionCountLimit(id, limit), '设置拆单数量上限成功');
  }

  const handlePriority = (id, priority) => {
    requestAndReplace(() => API.spPriority(id, priority), '设置订单优先级成功');
  }

  const handleIsUsable = (id, isUsable) => {
    requestAndReplace(() => API.spIsUsable(id, isUsable), '设置成功');
  }

  const handleRemarkSubmit = (text) => {
    requestAndReplace(() => API.spRemark(remark.id, text), '设置备注成功');
    setRemark(null)
  }

  const handlePortion = (id, trxId) => {
    if (!id || !trxId) {
      setSnackbar('请输入存款订单号', 'error');
      return;
    }
    async function func() {
      const info = await API.spPortion(id, trxId);
      if (info && info.success) {
        setSnackbar('操作成功', 'success');
      } else {
        setSnackbar(info.message, 'error');
      }
      setVersion(v => v + 1)
    }
    func();
    handleOpenPortions(id);
  }

  const handleOpenPortions = (id) => {
    async function func() {
      const info = await requestAndReplace(() => API.spLock(id), '锁定成功', true);
      if (!info || !info.success) {
        return;
      }

      const details = await API.spInfo(id);
      if (!details || !details.success) {
        setSnackbar('查询失败', 'error');
        return;
      }

      const portions = await API.spPortions(id);
      if (!portions || !portions.success) {
        setSnackbar('查询失败', 'error');
        return;
      }
      setPortions({ details: details.result, portions: portions.result });
    }
    func();
  }

  const handleClosePortions = (id, unlock) => {
    setPortions(null);
    if (unlock) {
      handleUnlock(id)
    }
  }

  const handleOpenPortionLimit = (id, limit) => {
    async function func() {
      const info = await API.spPortions(id);
      if (!info || !info.success) {
        setSnackbar('查询失败', 'error');
        return;
      }
      setPortionLimit({ id, portions: info.result, limit });
    }
    func()
  }

  const handleBankName = (id, bankNAme) => {
    if (!id || !bankNAme) {
      setSnackbar('请输入银行名称', 'error');
      return;
    }
    requestAndReplace(() => API.spBankName(id, bankNAme), '修改银行名称成功', true);
    setSpIdForBankName(null);
  }

  const copied = (text) => {
    setSnackbar('已复制', 'success');
    navigator.clipboard.writeText(text);
  }

  const lockCell = (id, auditor, isUsable) => {
    return (
      <LockGroup
        id={id}
        me={store.name}
        auditor={auditor}
        isUsable={isUsable}
        onLock={handleLock}
        onUnlock={handleUnlock}
        onOpenPortions={handleOpenPortions}
        onUsable={handleIsUsable}
      />
    )
  }

  const scrollToRowIndex = (rowIndex) => {
    setHighlightRow(rows[rowIndex].id);
    setTimeout(() => {
      setHighlightRow(null);
    }, 1000);
    setCoordinates({
      ...coordinates,
      rowIndex
    })
  }

  const amountButton = ({ min, max }) => {
    const translate = (number) => {
      if (number >= 100000) {
        return null;
      }
      if (number >= 10000) {
        return `${Math.floor(number / 10000)}w`;
      } if (number >= 1000) {
        return `${Math.floor(number / 1000)}k`;
      }
      return number;
    }
    const scrollToAmount = (min, max) => {
      const rowIndex = rows.findIndex(v => min <= v.remainingAmount && v.remainingAmount <= max);
      if (rowIndex < 0) {
        return
      }
      scrollToRowIndex(rowIndex)
    };

    const minText = translate(min);
    const maxText = max ? translate(max) : null;
    const text = maxText ? `${minText}-${maxText}` : `${minText}+`
    return (
      <Button
        key={min}
        onClick={() => scrollToAmount(min, max)}
        sx={{
          opacity: 0.5,
          color: 'white',
          width: 80,
          padding: '10px 2px'
        }}
        variant="contained"
        color="success">
        {text}
      </Button>
    )
  }

  const amountSelectionButtons = () => {
    const numbers = [100, 200, 500, 1000, 5000, 10000];
    const ranges = [];
    for (let i = 0; i < numbers.length; i += 1) {
      // 使用 slice 方法获取当前位置开始的指定长度的元素
      ranges.push(numbers.slice(i, i + 2));
    }
    return (
      <Box sx={{
        position: 'absolute', // 或 'fixed' 根据需求
        top: '20%', // 调整为需要的位置
        right: '0%', // 调整为需要的位置
        zIndex: 2 // 确保这个值高于ComponentA的
      }}>
        <Stack direction='column' spacing={1}>
          {ranges.reverse().map(v => amountButton({ min: v[0], max: v[1] }))}
        </Stack>
      </Box>
    );
  }

  const portionCount = (id, count, limit) => {
    const content = limit === 1
      ? count
      : <div>{count} / <span style={{ color: 'red' }}>{limit}</span></div>
    return (
      <Tooltip title="设置拆单数量上限">
        <Button
          size="small"
          onClick={() => handleOpenPortionLimit(id, limit)}
        >{content}
        </Button>
      </Tooltip>
    )
  }

  const amount = (id, amount, priority) => {
    return (
      <Priority
        amount={amount}
        value={priority}
        onSubmit={(v) => handlePriority(id, v)}
      />
    )
  }

  const handleCellClick = (params, event) => {
    // Prevents the default event behavior
    event.stopPropagation();

    if (params.field !== 'remark') {
      return;
    }
    setRemark({ id: params.id, value: params.value })
  };

  const lockedRows = rows.filter(v => v.auditor === store.name);

  const anchorPosition = { top: 60, left: window.innerWidth / 2 };
  return <>
    <Popover
      open={Boolean(opLogsId)}
      onClose={() => setOpLogsId(null)}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <OpLogs pid={opLogsId} />
    </Popover>
    <Popover
      open={Boolean(portions != null)}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {portions &&
        <Potions
          details={portions.details}
          portions={portions.portions}
          onSubmit={handlePortion}
          onClose={handleClosePortions}
        />}
    </Popover>
    <Popover
      open={Boolean(portionLimit != null)}
      onClose={() => setPortionLimit(null)}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {portionLimit &&
        <PortionLimit
          id={portionLimit.id}
          portions={portionLimit.portions}
          countLimit={portionLimit.limit}
          onSubmit={handlePortionCountLimit}
        />}
    </Popover>
    <SingleInputDialog
      open={Boolean(spIdForBankName)}
      title='修改银行名称'
      onSubmit={(v) => { handleBankName(spIdForBankName, v) }}
      onClose={() => setSpIdForBankName(null)}
    />
    <Popover
      open={Boolean(remark != null)}
      onClose={() => setRemark(null)}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {remark &&
        <Remark
          id={remark.id}
          value={remark.value}
          onSubmit={handleRemarkSubmit}
        />}
    </Popover>
    {amountSelectionButtons()}
    {
      lockedRows.length !== 0 &&
      <LockedCountdown
        rows={rows.filter(v => v.auditor === store.name)}
        onClick={(row) => {
          const index = rows.findIndex(v => v.id === row.id);
          scrollToRowIndex(index);
        }} />
    }
    <StandardSearchGroup
      options={[
        { type: 'date', name: 'timeStart', label: '开始时间', },
        { type: 'date', name: 'timeEnd', label: '结束时间', },
        { type: 'text', name: 'platOrderId', label: '平台订单号' },
        { type: 'text', name: 'orderId', label: '商户订单号' },
        { type: 'text', name: 'amountMin', label: '最小金额', number: true, width: 100 },
        { type: 'text', name: 'amountMax', label: '最大金额', number: true, width: 100 },
        {
          type: 'select', name: 'accountId', label: '商户', params: { value: queryParams.accountId },
          items: store.merchants ? store.merchants.map(v => { return { label: v.name, value: v.accountId } }) : []
        },
        {
          type: 'select', name: 'proId', label: '服务商', params: { value: queryParams.proId },
          items: store.providers ? store.providers.map(v => { return { label: v.name, value: v.id } }) : []
        },
      ]}
      setVersion={setVersion}
      setQueryParams={(v) => {
        setQueryParams({ ...queryParams, ...v })
      }}
    />

    <StandardTable
      columns={[
        {
          headerName: '创建时间', field: 'createTime',
          renderCell: (params) => dayjs(params.row.createTime).format('YY-MM-DD HH:mm')
        },
        { headerName: '商户', field: 'mchName', },
        { headerName: '服务商', field: 'proName', },
        {
          headerName: '订单号', field: 'platOrderId', width: 170,
          renderCell: (params) => (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', // 使子元素在水平方向上居中
              '& > *:not(:last-child)': { mb: -0.5 },
            }}>
              <CopyableText label={params.row.platOrderId} onClick={() => copied(params.row.platOrderId)} />
              <CopyableText label={params.row.orderId} onClick={() => copied(params.row.orderId)} />
            </Box >)
        },
        {
          headerName: '银行卡信息', field: 'card', width: 200,
          renderCell: (params) => (
            <Button
              size="small"
              onClick={() => setSpIdForBankName(params.row.id)}
            >
              {params.row.card}
            </Button>
          ),
        },
        {
          headerName: '耗时(分)', field: 'duration', width: 100,
          renderCell: (params) => (
            <Button
              size="small"
              onClick={() => setOpLogsId(params.row.id)}
            >
              <TimeElapsed start={params.row.createTime} end={params.row.completeTime} />
            </Button>
          ),
        },
        {
          headerName: '已开单', field: 'portionCount', width: 70,
          renderCell: (params) => portionCount(params.row.id, params.row.portionCount, params.row.portionCountLimit)
        },
        {
          headerName: '未执行金额', field: 'remainingAmount', width: 90,
          renderCell: (params) => amount(params.row.id, params.row.remainingAmount, params.row.priority)
        },
        {
          headerName: '备注', field: 'remark',
          renderCell: (params) => params.row.remark,
        },
        {
          headerName: '锁定', field: 'id',
          renderCell: (params) => lockCell(params.row.id, params.row.auditor, params.row.isUsable)
        },
        { headerName: '审核人', field: 'auditor' },
        {
          headerName: '锁定计时', field: 'auditTime',
          renderCell: (params) =>
            params.row.auditTime != null
              ? (<TimeElapsed start={params.row.auditTime} />)
              : ''
        },
      ]}
      data={rows}
      query={query}
      summary={[
        { label: '总金额', value: <span style={{ color: 'red' }}>{summary.total}</span> },
        { label: '未执行总额', value: <span style={{ color: 'red' }}>{summary.remaining}</span> },
        { label: '订单总数', value: <span style={{ color: 'red' }}>{summary.count}</span> },
      ]}
      queryParams={queryParams}
      version={version}
      notFoundSrc={`no_items_found.png`}
      rowHeight={rowHeight}
      apiRef={apiRef}
      getRowClassName={(params) =>
        params.id === highlightRow ? 'highlight-row' : ''
      }
      pageDisable
      onCellClick={handleCellClick}
    />
  </>
}