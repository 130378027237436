import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { bgBlur } from '../../../utils/cssStyles';
import Iconify from '../../../components/iconify';
import AccountPopover from './AccountPopover';
import API from '../../../utils/api';

const NAV_WIDTH = 240;
const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 50;
const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  backgroundColor: 'white',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 1),
  },
}));

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const [alarm, setAlarm] = useState(false);

  // useEffect(() => {
  //   const queryAlarm = () => API.spPortionAlarms(v => setAlarm(v.length > 0));
  //   // Set an interval to update every second
  //   const intervalId = setInterval(queryAlarm, 5000);
  //   return () => clearInterval(intervalId);
  // }, []);
  return (
    <StyledRoot>
      <StyledToolbar
        sx={{
          backgroundColor: alarm ? 'red' : 'transparent'
        }}
      >
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
